<template>
  <div class="content contentBox">
    <div class="list-title">
      <h4 class="title">草稿文件列表页</h4>
    </div>
    <div class="searchBar">
      <div>
        <el-input
          placeholder="请输入合同名称、经办人等关键词进行搜索"
          class="inputStyle"
          size="small"
          v-model="keyWord"
          clearable
          @clear="resetSearchList"
          @keyup.enter.native="getListBySearch"
        >
          <i
            slot="prefix"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
      </div>
      <!-- 按钮组件 -->
      <div>
        <button
          class="btn btn-primary"
          @click="getListBySearch()"
        >查询</button>
        <button
          class="btn btn-lucency"
          @click="resetSearchList()"
        >重置</button>
      </div>
    </div>
    <section class="tableBox">
      <el-table
        v-loading="tableLoading"
        ref="tableList"
        :data="tableData"
        style="width: 100%"
        border
        fit
        :header-row-style="{ height: '36px' }"
      >
        <el-table-column
          label="合同名称"
          prop="contractName"
          min-width="280"
        >
          <template slot-scope="scope">
            <div class="contractNameBox">
              <span
                class="nameLink"
                @click="goDetail(scope.row)"
              >{{
                scope.row.contractName
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableColumn"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.minWidth"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  name: "Draft",
  data() {
    return {
      tableLoading: false,
      keyWord: "",
      clickState: false,
      // 列表内容
      tableData: [],
      // 表头信息
      tableColumn: [
        {
          prop: "contractType",
          label: "合同类型",
          minWidth: 140
        },
        {
          prop: "operatorName",
          label: "经办人",
          minWidth: 140
        },
        {
          prop: "contractDate",
          label: "经办日期",
          minWidth: 140
        },
        {
          prop: "supplierCompany",
          label: "对方签约主体",
          minWidth: 280
        }
      ],

      // 分页
      currentPage: 1,
      total: 0,
      pageSize: 30
    };
  },
  methods: {
    // 重置
    resetSearchList() {
      this.keyWord = "";
    },
    // 查询
    getListBySearch(){
      this.currentPage = 1
      this.getDraftList()
    },
    // 跳转详情页
    goDetail(row) {
      // 判断类型
      let type = row.dataType;
      if (type == "contract_agreement") {
        // 补充协议
        this.$open({
          name: "agreementDraft",
          path: `/agreementdraft/${row.id}`,
          params: {
            id: row.id
          }
        });
      } else {
        // 合同草稿
        this.$open({
          name: "conDraft",
          path: `/condraft/${row.id}`,
          params: {
            id: row.id
          }
        });
      }
    },
    // 搜索草稿列表
    getDraftList() {
      this.tableLoading = true
      let params = {
        range: 'draft',
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        keyWord: this.keyWord.trim()
      }
      this.$request
        .post("/contract/getContractOrDraftList", { params })
        .then(res => {
          this.tableLoading = false
          if (res.code === "200") {
            let data = res.data.list
            this.tableData = data.list;
            this.total = Number(data.total)

            // 处理对方主体信息展示
            this.tableData.forEach(item => {
              if(item.supplierCompany) {
                item.supplierCompany = item.supplierCompany.slice(1, item.supplierCompany.length - 1)
              }else {
                item.supplierCompany = '-'
              }
              
            })
          }
        });
    },

    /**
     * 分页相关的方法
     */
    handleSizeChange(val){
      this.pageSize = val
      this.getDraftList()
    },
    handleCurrentChange(value){
      this.currentPage = value
      this.getDraftList()
    }
  },
  created() {
    this.getDraftList();
  }
};
</script>
<style lang="less" scoped>
.contentBox {
  min-height: 100vh;

  .list-title {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    line-height: 40px;
    padding: 6px 12px;
    border-bottom: 1px solid #eaeaea;
  }

  .searchBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 18px;
    padding: 0 12px;

    .inputStyle {
      width: 568px;
      height: 30px;
    }
  }
  .tableBox {
    padding: 0 12px;
    margin-top: 18px;

    .contractNameBox {
      .nameLink {
        color: #997236;
        padding: 3px 0px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .pagination {
    height: 36px;
    position: sticky;
    bottom: 0;
    right: 0;
    background: white;
    padding-top: 18px;
    z-index: 500;
    .el-pagination {
      position: absolute;
      right: 20px;
    }
  }
}
</style>